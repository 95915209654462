import React from "react"
import GameCard from "../smallGameCard"
import { Link } from "gatsby"
import Style from "./gameCategoryWrapper.module.scss"
import Ad from "../ad"
function GameCategoryWrapper({ category, data, viewAll, gameLink }) {
  const categoryCap = category[0].toUpperCase() + category.slice(1)
  const showData = viewAll ? data : data.slice(0, 6)

  return (
    <div className={Style.mainContainer}>
      <div className={Style.gameWrapperHeading}>
        <div className={Style.heading}>
          <h2 className={Style.categHeading}>{categoryCap}</h2>
          {data.length > 6 && (
            <span className={Style.gameCount}>{`${data.length} games`} </span>
          )}
        </div>
        {!viewAll && data.length > 6 && (
          <Link
            to={`/${category.toLowerCase().replace(/[^a-zA-Z0-9]/g, "-")}/`}
            className={Style.viewAll}
          >
            View All
          </Link>
        )}
      </div>
      <div className={Style.gameCardWrapper}>
        {showData.map((d, i) => (
          <GameCard data={d.node} key={i} gameLink={gameLink} />
        ))}
      </div>
      <Ad />
    </div>
  )
}

export default GameCategoryWrapper
