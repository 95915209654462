import React from "react"

const adFormats = {
  0: {
    id: 1679305375210,
  },
  1: {
    id: 1679305854969,
  },
  2: {
    id: 1679306076198,
  },
  3: {
    id: 1679306143397,
  },
  4: {
    id: 1679306214990,
  },
  5: {
    id: 1679305854969,
  },
  6: {
    id: 1679305854969,
    minHeight: "600px",
  },
}

export default function Ad({ adNum }) {
  const { id, minHeight } = adFormats[adNum || 0]
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {/* <div
        id={`div-gpt-ad-${id}-0`}
        style={{ minWidth: "300px", minHeight: minHeight || "300px" }}
      >
        <script
          dangerouslySetInnerHTML={{
            __html: `googletag.cmd.push(function() { googletag.display('div-gpt-ad-${id}-0'); });`,
          }}
        ></script>
      </div> */}
    </div>
  )
}
